<template>
  <div>
    <dashboard-page-title :showAddBtn="false">{{ $t('contactus.contacts') }}</dashboard-page-title>

      <form >
        <b-row>
          <b-col md="12" class="mb-3">
            <input-form :placeholder="$t('main.subject')" :label="$t('main.subject')" name="subject" validate="required" v-model="item.subject" :disabled="disabled"/>
          </b-col>
          <b-col md="12" class="mb-3">
            <input-form :placeholder="$t('main.email')" :label="$t('main.email')" name="email" validate="required" v-model="item.email" :disabled="disabled"/>
          </b-col>
          <b-col md="12" class="mb-3">
            <textarea-form :placeholder="$t('main.message')" :label="$t('main.message')" name="message" validate="required" v-model="item.message" :disabled="disabled"/>
          </b-col>
        </b-row>
      </form>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import globalAdd from '@/mixins/globalAdd'
import contactusService from '../services/contacts'
// import countriesServices from '../../countries/services/countries'

export default {
  mixins: [globalAdd],
  mounted () {
    core.index()
  },
  props: { id: { type: [String, Number], default: '' } },
  data () {
    return {
      pageName: this.$route?.meta?.name || '',
      loadingSubmit: true,
      progressImage: 0,
      item: {
        subject: '',
        email: '',
        message: ''
      },
      countries: []
    }
  },
  methods: {
    getDetails () {
      contactusService.getDetails(this.id).then(response => {
        const data = response?.data?.data
        console.log('data', data)
        this.item = {
          subject: data?.subject,
          email: data?.email,
          message: data?.message
        }
        this.loadingSubmit = false
      })
    }
  },
  created () {
    if (this.id) {
      console.log('this.id', this.id)
      this.getDetails()
    } else {
      this.loadingSubmit = false
    }
  }
}
</script>
