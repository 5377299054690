import { website } from '@/axios'
export const contactsLink = 'admin/contact-us'

export default {
  getDetails (id) {
    return website().get(`${contactsLink}/${id}`)
  },
  add (payload) {
    return website().post(contactsLink, payload)
  },
  update (id, payload) {
    return website().patch(`${contactsLink}/${id}`, payload)
  },
  getAll () {
    return website().get(`${contactsLink}`)
  }
}
